export default {
    brandings: [
        {
            name: 'IndyVit staging',
            subdomainQuiz: 'indyvit-frontend-staging.herokuapp.com',
            subdomainExpertMode: 'indyvit-expert-staging.herokuapp.com',
            logo: '/assets/branding/indyvit/logo.svg',
            logoBlack: '/assets/branding/indyvit/logo-black.svg',
            showIndyVitLogo: false,
            backButtonUrl: 'https://indyvit.com/',
        },
        {
            name: 'IndyVit review app',
            subdomainQuiz: 'indyvit-quiz-new-api-ve-vge3kh.herokuapp.com',
            subdomainExpertMode: 'indyvit-expert-new-api-ve-vge3kh.herokuapp.com',
            logo: '/assets/branding/indyvit/logo.svg',
            logoBlack: '/assets/branding/indyvit/logo-black.svg',
            showIndyVitLogo: false,
            backButtonUrl: 'https://indyvit.com/',
        },
        {
            name: 'Localhost',
            subdomainQuiz: 'localhost',
            subdomainExpertMode: '123localhost',
            logo: '/assets/branding/indyvit/logo.svg',
            logoBlack: '/assets/branding/indyvit/logo-black.svg',
            logoLabelPNG: '/assets/branding/sportxx/logo.png',
            showIndyVitLogo: false,
            backButtonUrl: 'http://localhost:8080/'
        },
        {
            name: 'IndyVit',
            subdomainQuiz: 'deinmix.indyvit.com',
            subdomainExpertMode: 'expert.indyvit.com',
            logo: '/assets/branding/indyvit/logo.svg',
            logoBlack: '/assets/branding/indyvit/logo-black.svg',
            showIndyVitLogo: false,
            backButtonUrl: 'https://indyvit.com/'
        },
        {
            name: 'Doctors365',
            subdomainQuiz: 'supplementquiz.doctors365.org',
            subdomainExpertMode: 'supplementconfig.doctors365.org',
            logo: '/assets/branding/indyvit/logo.svg',
            logoBlack: '/assets/branding/indyvit/logo-black.svg',
            showIndyVitLogo: false,
            backButtonUrl: 'https://doctors365.org/',
            disableCheckout: true
        },
        {
            name: 'Diantha',
            subdomainQuiz: 'diantha.indyvit.com',
            subdomainExpertMode: 'diantha-expert.indyvit.com',
            logo: '/assets/branding/diantha/diantha-logo.png',
            logoBlack: '/assets/branding/diantha/diantha-logo.png',
            logoLabelPNG: '/assets/branding/diantha/diantha-logo.png',
            showIndyVitLogo: true,
            backButtonUrl: 'https://indyvit.com/'
        }, {
            name: 'Sport XX',
            subdomainQuiz: 'sportxx.indyvit.com',
            subdomainExpertMode: 'sportxx-expert.indyvit.com',
            logo: '/assets/branding/sportxx/logo.png',
            logoBlack: '/assets/branding/sportxx/logo.png',
            logoLabelPNG: '/assets/branding/sportxx/logo.png',
            showIndyVitLogo: true,
            backButtonUrl: 'https://indyvit.com/'
        }, {
            name: 'Undertraining',
            subdomainQuiz: 'undertraining.indyvit.com',
            subdomainExpertMode: 'undertraining-expert.indyvit.com',
            logo: '/assets/branding/undertraining/logo.png',
            logoBlack: '/assets/branding/undertraining/logo.png',
            logoLabelPNG: '/assets/branding/undertraining/logo.png',
            showIndyVitLogo: true,
            backButtonUrl: 'https://indyvit.com/'
        }, {
            name: 'Aurum',
            subdomainQuiz: 'aurum.indyvit.com',
            subdomainExpertMode: 'aurum-expert.indyvit.com',
            logo: '/assets/branding/aurum/logo.png',
            logoBlack: '/assets/branding/aurum/logo.png',
            logoLabelPNG: '/assets/branding/aurum/logo.png',
            showIndyVitLogo: true,
            backButtonUrl: 'https://indyvit.com/'
        }
    ]
}
